import React from "react";

//Elements
import Modal from "../../_elements/Modal";

const index = () => {
  return (
    <Modal id="set-modal">
      <div className="card">Set Modal</div>
    </Modal>
  );
};

export default index;
