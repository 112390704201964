import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../../redux/features/General/GeneralSlice";
import { motion } from "framer-motion";
//Components
import UserHeader from "./UserHeader";
import UserSection from "./UserSection";
import Selections from "./Selections";
import Footer from "../../components/Footer";
import VideoEmbed from "./VideoEmbed";
import Genres from "./Genres";
import { otherLinks } from "../../services/mocks/user";

const Index = () => {
  let { id } = useParams();
  const { data: user } = useSelector((state) => state.general.userView);
  const dispatch = useDispatch();

  //Get User Data
  useEffect(() => {
    const fetch = async () => {
      dispatch(fetchUser(1));
    };
    fetch();
    console.log(`Fetching user ${id} data`);
  }, []);

  return (
    <div id="user" className="view">
      <UserHeader user={user} />
      {/* <Genres genres={user.genres} /> */}
      <div className="container" id="user-content">
        {/* <Selections selections={user.selections} /> */}
        {(user.hightlightedVideoUrl !== "" || user.otherLinks.length > 0) && (
          <div className="video-links-wrapper">
            {user.hightlightedVideoUrl !== "" && (
              <VideoEmbed url={user.hightlightedVideoUrl} />
            )}
            {user.otherLinks.length > 0 && (
              <div className="other-links-wrapper">
                <div className="text">Links</div>
                <div className="other-links">
                  {user.otherLinks.map((link) => (
                    <a href={link.link} target="_blank" className="link">
                      <div className="title">{link.title}</div>
                      <div className="description">{link.description}</div>
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        {user.tracks.length > 0 && (
          <UserSection
            format="square"
            items={user.tracks}
            id="tracks"
            title="Últimos lanzamientos"
          />
        )}
        {user.playlists.length > 0 && (
          <UserSection
            items={user.playlists}
            id="playlists"
            format="square"
            title="Playlists"
          />
        )}

        {user.sets.length > 0 && (
          <UserSection format="wide" items={user.sets} id="sets" title="Sets" />
        )}
        {user.events.length > 0 && (
          <UserSection
            format="wide"
            items={user.events}
            id="events"
            title="Eventos"
          />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Index;
