import { configureStore } from "@reduxjs/toolkit";
import modalsReducer from "./features/Modals/ModalSlice";
import generalReducer from "./features/General/GeneralSlice";
import { userSlice } from "./features/User/UserSlice";

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    general: generalReducer,
    modals: modalsReducer,
  },
});
