import React from "react";
import "./index.sass";
import { useDispatch, useSelector } from "react-redux";
import { handleSongModal } from "../../../redux/features/Modals/ModalSlice";

//Icons
import { SiSoundcloud, SiSpotify, SiTidal, SiYoutube } from "react-icons/si";
import { RiDownloadCloud2Fill } from "react-icons/ri";

//Elements
import Modal from "../../_elements/Modal";
import CloseButton from "../../_elements/CloseButton";

const Index = () => {
  const { data } = useSelector((state) => state.modals.songModal);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(handleSongModal());
  };

  return (
    <Modal id="song-modal">
      <div className="card">
        <CloseButton handleClose={handleClose} />
        <>
          <div className="left-wrapper">
            <div className="image">
              <img src={data.image} alt="" />
            </div>
            <iframe
              src="https://open.spotify.com/embed/track/0lJp6t1xChJgEXZL7PyT1S"
              width="100%"
              height="80"
              frameBorder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          </div>
          <div className="right-wrapper">
            <div className="top-right-wrapper">
              {data.genres && (
                <div className="genres">
                  {data.genres.map((genre) => (
                    <div className="genre">{genre}</div>
                  ))}
                </div>
              )}
              {/* <div className="date">{new Date().toLocaleDateString("es-ar")}</div> */}
              <div className="name">{data.name}</div>

              <div className="artists">
                {data.artists.map((artist) => (
                  <div className="artist">{artist}</div>
                ))}
                {data.mix && data.mix !== "" && (
                  <div className="mix">({data.mix})</div>
                )}
              </div>
            </div>

            <div className="bottom-right-wrapper">
              <div className="text">Disponible en</div>
              <div className="buttons">
                <div className="button" id="spotify">
                  <SiSpotify />
                </div>
                <div className="button" id="soundcloud">
                  <SiSoundcloud />
                </div>
                <div className="button" id="youtube">
                  <SiYoutube />
                </div>
                <div className="button" id="beatport">
                  <img
                    src="https://3os4wq4e5oaz2u72m72et884-wpengine.netdna-ssl.com/wp-content/uploads/2019/02/Beatport-hype-headphone-logo.png"
                    alt=""
                  />
                </div>
                <div className="button" id="tidal">
                  <SiTidal />
                </div>
                <div className="button" id="external">
                  <RiDownloadCloud2Fill />
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </Modal>
  );
};

export default Index;
