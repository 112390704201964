import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { initialState } from "./utils";

import { fetchUser as fetchUserService } from "../../../services/UserView.services";

export const fetchUser = createAsyncThunk(
  "userView/fetchUser",
  async ({ username }, thunkAPI) => {
    try {
      const response = await fetchUserService();
      console.log(response);
      if (response.status === 200) {
        return response;
      } else {
        return thunkAPI.rejectWithValue(response.status);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const GeneralSlice = createSlice({
  name: "general",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUser.fulfilled]: (state, { payload }) => {
      console.log("payload", payload);
      state.userView.data = payload.data;
    },
    [fetchUser.pending]: (state) => {
      console.log("Pending");
      state.userView.isFetching = true;
    },
    [fetchUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
  },
});

export const {} = GeneralSlice.actions;

export default GeneralSlice.reducer;
