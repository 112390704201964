import React from "react";
import { Link } from "react-router-dom";
import "./index.sass";

const index = () => {
  return (
    <footer>
      Creado con
      <Link to="/">Beathub</Link>
    </footer>
  );
};

export default index;
