export const fadeUp = {
  hidden: { opacity: 0, y: 10 },
  visible: (custom) => ({
    opacity: 1,
    y: 0,
    transition: { delay: custom / 8 },
  }),
  exit: { opacity: 0 },
};

export const fade = {
  hidden: { opacity: 0 },
  visible: (custom) => ({
    opacity: 1,
    transition: { delay: custom || 0 },
  }),
  exit: { opacity: 0 },
};

export const fadeImage = {
  hidden: { opacity: 0, scale: 1, filter: "blur(0px)" },
  visible: (custom) => ({
    opacity: 1,
    scale: 1.15,
    filter: "blur(2px)",
    transition: { delay: custom || 0, duration: 2 },
  }),
  exit: { opacity: 0 },
};
