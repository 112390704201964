export const initialState = {
  userView: {
    data: {
      id: "",
      username: "",
      email: "",
      name: "",
      shortDescription: "",
      occupations: [],
      location: "",
      hightlightedVideoUrl: "",
      otherLinks: [],
      links: {
        instagram: "",
        spotify: "",
        youtube: "",
        soundcloud: "",
        twitch: "",
        cafecito: "",
      },
      coverImage: "",
      profileImage: "",
      tracks: [],
      playlists: [],
      events: [],
      sets: [],
      genres: [],
    },

    //Fetching
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: "",
  },
};
