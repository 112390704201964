import React from "react";

const index = () => {
  return (
    <div className="view" id="login">
      Login
    </div>
  );
};

export default index;
