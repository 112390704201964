export const handleConstrains = (format, items) => {
  let constraints = 0;

  //If its Square
  if (format === "square") {
    if (items < 3) {
      constraints = items * 100;
    } else {
      constraints = items * 215;
    }
  }

  //If its Wide
  if (format === "wide") {
    if (items < 3) {
      constraints = items * 200;
    } else {
      constraints = items * 350;
    }
  }

  return constraints;
};
