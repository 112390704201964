import React from "react";
import "./index.sass";
import { useDispatch } from "react-redux";
import {
  handleSongModal,
  handleSetModal,
  handlePlaylistModal,
} from "../../../../../redux/features/Modals/ModalSlice";
import { motion } from "framer-motion";
//Elements
import Button from "../../../../../components/_elements/Button";
import { fadeUp } from "../../../../../utils/variants";

const Index = ({ format, item, id }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    switch (id) {
      case "tracks":
        dispatch(handleSongModal({ data: item }));
        break;
      case "sets":
        dispatch(handleSetModal({ data: item }));
        break;
      case "playlists":
        dispatch(handlePlaylistModal({ data: item }));
        break;
    }
  };

  return (
    <motion.div
      variants={fadeUp}
      initial="hidden"
      animate="visible"
      custom={1}
      onTap={() => handleClick()}
      className={`item ${format}`}
    >
      {item.date && (
        <div className="date">{item.date.toLocaleDateString("es-ar")}</div>
      )}
      <div
        className="image"
        style={{ backgroundImage: `url(${item.image})` }}
      ></div>
      <div className="item-content">
        <div className="name">{item.name}</div>
        {id === "playlists" && (
          <div className="artists">
            {item.artists.map((artist) => (
              <div className="artist">{artist}</div>
            ))}
          </div>
        )}
        {id === "events" && item.link && <Button text="Ver evento" />}
      </div>
    </motion.div>
  );
};

export default Index;
