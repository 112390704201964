import React, { useEffect, useState } from "react";
import "./index.sass";
import { motion } from "framer-motion";
import { BiPlus } from "react-icons/bi";
import { handleConstrains } from "./utils";
// import useWindowDimensions from "../../../../hooks/useWindowDimensions";

//Components
import Item from "./Item";

const Index = ({ items, format, id }) => {
  // const { height, width } = useWindowDimensions();
  const [leftConstraints, setLeftConstraints] = useState(items.length * 215);

  useEffect(() => {
    let leftConstraints = handleConstrains(format, items.length);
    console.log(leftConstraints);
    setLeftConstraints(leftConstraints);
  }, [format, id, items]);

  return (
    <div className="carousel">
      <motion.div
        drag="x"
        dragConstraints={{
          left: -leftConstraints,
          right: 0,
        }}
        className="items"
      >
        {items.map((item) => (
          <Item format={format} item={item} id={id} />
        ))}

        {items.length >= 10 && (
          <div id="see-more" className="item">
            <BiPlus />
            <div className="name"> See More</div>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default Index;
