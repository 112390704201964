import React from "react";

const index = () => {
  return (
    <div id="home" className="view">
      Home
    </div>
  );
};

export default index;
