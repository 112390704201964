import React, { useEffect } from "react";
import "./index.sass";
import ReactPlayer from "react-player";

const Index = ({ url }) => {
  return (
    <div className="video-embed-container">
      <ReactPlayer width="100%" className="react-player" url={url} />
    </div>
  );
};

export default Index;
