import React from "react";

const index = () => {
  return (
    <div id="discover" className="view">
      Discover
    </div>
  );
};

export default index;
