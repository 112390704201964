
  export const variantsContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        ease: "easeInOut",
        when: "beforeChildren",
      },
    },
  };

  export const variants = {
    hidden: { opacity: 0, y: "20" },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeInOut",
        when: "beforeChildren",
      },
    },
  };
