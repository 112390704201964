import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";

//Modals
import SongModal from "./components/_modals/SongModal";
import ArtistModal from "./components/_modals/ArtistModal";
import PlaylistModal from "./components/_modals/PlaylistModal";
import EventModal from "./components/_modals/EventModal";
import SetModal from "./components/_modals/SetModal";

//Views
import Home from "./views/Home";
import User from "./views/User";
import Discover from "./views/Discover";
import Register from "./views/Auth/Register";
import Login from "./views/Auth/Login";

function App() {
  const modals = useSelector((state) => state.modals);
  console.log(modals);
  return (
    <div className="App">
      {/* //// -- Modals -- //// */}
      {/* Song Modal */}
      <AnimatePresence>
        {modals.songModal.state && <SongModal />}
      </AnimatePresence>

      {/* Artist Modal */}
      <AnimatePresence>
        {modals.artistModal.state && <ArtistModal />}
      </AnimatePresence>

      {/* Playlist Modal */}
      <AnimatePresence>
        {modals.playlistModal.state && <PlaylistModal />}
      </AnimatePresence>

      {/* Set Modal */}
      <AnimatePresence>{modals.setModal.state && <SetModal />}</AnimatePresence>

      {/* Event Modal */}
      <AnimatePresence>
        {modals.eventModal.state && <EventModal />}
      </AnimatePresence>

      <Router>
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/u/:id" component={User}></Route>
          <Route path="/login" component={Login}></Route>
          <Route path="/register" component={Register}></Route>
          <Route path="/discover" component={Discover}></Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
