import React from "react";

const index = () => {
  return (
    <div className="view" id="register">
      Register
    </div>
  );
};

export default index;
