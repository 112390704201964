import React from "react";
import { CgClose } from "react-icons/cg";

const index = ({ handleClose }) => {
  return (
    <div onClick={() => handleClose()} className="close-button">
      <CgClose />
    </div>
  );
};

export default index;
