import React from "react";
import "./index.sass";
import { motion } from "framer-motion";
import { fadeUp } from "../../../../utils/variants";
//Icons
import { BiWorld } from "react-icons/bi";

//Components
import Links from "./Links";

const index = ({ user }) => {
  const Occupations = ({ resp }) => (
    <motion.div
      variants={fadeUp}
      custom={1}
      className={!resp ? "occupations" : "occupations-resp"}
    >
      {user.occupations.map((oc) => (
        <div className="occupation">{oc}</div>
      ))}
    </motion.div>
  );

  return (
    <motion.div
      variants={fadeUp}
      initial="hidden"
      animate="visible"
      className="user-info"
    >
      <div className="wrapper-top">
        <div className="wrapper-top-left">
          <div className="location">
            <BiWorld />
            {user.location}
          </div>
          <Occupations />
        </div>
        <div className="wrapper-top-right">
          <Links links={user.links} />
        </div>
      </div>
      <div className="wrapper-bottom">
        <div className="wrapper-bottom-left">
          <motion.div
            custom={0.5}
            variants={fadeUp}
            style={{ backgroundImage: `url(${user.profileImage})` }}
            className="profile-image-resp"
          ></motion.div>
        </div>
        <div className="wrapper-bottom-right">
          <motion.div custom={2} variants={fadeUp} className="name">
            {user.name}
          </motion.div>
          <motion.div custom={3} variants={fadeUp} className="description">
            {user.shortDescription}
          </motion.div>
          <Occupations resp />
        </div>
      </div>
      <div className="description-resp">{user.shortDescription}</div>
    </motion.div>
  );
};

export default index;
