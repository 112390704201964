import React from "react";
import "./index.sass";
import { motion } from "framer-motion";
import { variants } from "./utils";

//Icons
import {
  RiInstagramFill,
  RiSpotifyFill,
  RiYoutubeFill,
  RiTwitchFill,
  RiSoundcloudFill,
} from "react-icons/ri";

const Link = ({ id, link, Icon, imageUrl, delay }) => {
  if (link) {
    return (
      <motion.a
        variants={variants}
        initial="hidden"
        animate="visible"
        exit="exit"
        id={id}
        custom={delay}
        target="_blank"
        href={link}
      >
        <div className="link">
          {Icon && <Icon />}
          {imageUrl && <img src={imageUrl}></img>}
        </div>
      </motion.a>
    );
  } else {
    return <></>;
  }
};

const Links = ({ links }) => {
  return (
    <motion.div
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="links"
    >
      <Link delay={1} id="spotify" Icon={RiSpotifyFill} link={links.spotify} />
      <Link delay={2} id="youtube" Icon={RiYoutubeFill} link={links.youtube} />
      <Link
        delay={3}
        id="instagram"
        Icon={RiInstagramFill}
        link={links.instagram}
      />
      <Link delay={4} id="twitch" Icon={RiTwitchFill} link={links.twitch} />
      <Link
        delay={5}
        id="soundcloud"
        Icon={RiSoundcloudFill}
        link={links.soundcloud}
      />
      <Link
        delay={6}
        id="cafecito"
        imageUrl="https://cdn.cafecito.app/imgs/cafecito_logo.svg"
        link={links.cafecito}
      />
    </motion.div>
  );
};

export default Links;
