import React from "react";
import "./index.sass";
import { useDispatch, useSelector } from "react-redux";
import { handlePlaylistModal } from "../../../redux/features/Modals/ModalSlice";

//Icons
import { SiSoundcloud, SiSpotify, SiTidal, SiYoutube } from "react-icons/si";
import { RiDownloadCloud2Fill } from "react-icons/ri";

//Elements
import Modal from "../../_elements/Modal";
import CloseButton from "../../_elements/CloseButton";

const Index = () => {
  const { data } = useSelector((state) => state.modals.playlistModal);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(handlePlaylistModal());
  };

  return (
    <Modal id="playlist-modal">
      <div className="card">
        <CloseButton handleClose={handleClose} />
        <div className="left-wrapper">
          <div className="image">
            <img src={data.image} alt="" />
          </div>
          <div className="open-spotify">
            <SiSpotify />
            <a
              href={`https://open.spotify.com/playlist/${data.playlistId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Abrir en Spotify
            </a>
          </div>
          <div className="top-left-wrapper">
            {data.genres && (
              <div className="genres">
                {data.genres.map((genre) => (
                  <div className="genre">{genre}</div>
                ))}
              </div>
            )}
            {/* <div className="date">{new Date().toLocaleDateString("es-ar")}</div> */}
            <div className="name">{data.name}</div>

            <div className="artists">
              {data.artists.map((artist) => (
                <div className="artist">{artist}</div>
              ))}
              {data.mix && data.mix !== "" && (
                <div className="mix">({data.mix})</div>
              )}
            </div>
          </div>
        </div>
        <div className="right-wrapper">
          <iframe
            src={`https://open.spotify.com/embed/playlist/${data.playlistId}?theme=0`}
            width="100%"
            height="500"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
        </div>
      </div>
    </Modal>
  );
};

export default Index;
