import React from "react";
import { motion } from "framer-motion";
import { fadeImage } from "../../../utils/variants";

//Components
import UserInfo from "./UserInfo";

const UserHeader = ({ user }) => {
  return (
    <div className="user-header">
      <div className="container">
        <motion.div
          style={{ backgroundImage: `url(${user.profileImage})` }}
          initial={{ rotateZ: 50 }}
          animate={{
            rotateZ: 0,
            transition: { ease: "anticipate", duration: 2 },
          }}
          whileHover={{
            rotateZ: 30,
            transition: { ease: "anticipate", duration: 1 },
          }}
          className="profile-image"
        ></motion.div>
        <UserInfo user={user} />
      </div>
      <motion.div
        variants={fadeImage}
        initial="hidden"
        custom={1}
        animate="visible"
        style={{ backgroundImage: `url(${user.coverImage})` }}
        className="cover-image"
      ></motion.div>
    </div>
  );
};

export default UserHeader;
