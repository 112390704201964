import React from "react";
import "./index.sass";
import { fadeUp } from "../../../utils/variants";
import { motion } from "framer-motion";
//Components
import Carousel from "./Carousel";

const Index = ({ id, title, format, children, items }) => {
  return (
    <section className="user-section" id={id}>
      <div className="section-top-wrapper">
        <motion.div
          variants={fadeUp}
          initial="hidden"
          animate="visible"
          className="section-title"
        >
          {title}
        </motion.div>
      </div>

      {items && <Carousel id={id} format={format} items={items} />}
      {items.length === 0 && (
        <div className="empty">Aún no hay elementos para mostrar</div>
      )}
    </section>
  );
};

export default Index;
