import React from "react";
import "./index.sass";
import { useDispatch } from "react-redux";
import { handleModal } from "../../../redux/features/Modals/ModalSlice";
import { motion } from "framer-motion";
import { variants, variantsContainer } from "./utils";

const Modal = ({ id, children, notClickAway, handleClose }) => {
  //Redux
  const dispatch = useDispatch();

  const handleModalClose = () => {
    if (handleClose) {
      handleClose();
    } else {
      console.log("Handleing close", id);
      dispatch(handleModal(id));
    }
  };

  return (
    <motion.div
      variants={variantsContainer}
      initial="hidden"
      animate="visible"
      exit="hidden"
      id={id}
      className="modal"
    >
      <motion.div variants={variants} className="content">
        {children}
      </motion.div>
      <div onClick={() => handleModalClose()} className="overlay"></div>
    </motion.div>
  );
};

export default Modal;
