import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./utils";

export const ModalSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    handleModal: (state, action) => {
      switch (action.payload) {
        case "song-modal":
          state.songModal.state = false;
          break;
        case "artist-modal":
          state.artistModal.state = false;
          break;
        case "playlist-modal":
          state.playlistModal.state = false;
          break;
        case "event-modal":
          state.eventModal.state = false;
          break;
        case "set-modal":
          state.setModal.state = false;
          break;
        default:
          break;
      }
    },
    handleSongModal: (state, action) => {
      console.log("Handleing Song Modal");
      if (action.payload) {
        state.songModal.data = action.payload.data;
        state.songModal.state = true;
      } else {
        state.songModal.state = false;
      }
    },
    handlePlaylistModal: (state, action) => {
      console.log("Handleing Playlist Modal");
      if (action.payload) {
        state.playlistModal.data = action.payload.data;
        state.playlistModal.state = true;
      } else {
        state.playlistModal.state = false;
      }
    },
    handleSetModal: (state, action) => {
      console.log("Handleing Set Modal");
      if (action.payload) {
        state.setModal.data = action.payload.data;
        state.setModal.state = true;
      } else {
        state.setModal.state = false;
      }
    },
    handleArtistModal: (state, action) => {
      console.log("Handleing Artist Modal");
      if (action.payload) {
        state.artistModal.data = action.payload.data;
        state.artistModal.state = true;
      } else {
        state.artistModal.state = false;
      }
    },
    handleEventModal: (state, action) => {
      console.log("Handleing Event Modal");
      if (action.payload) {
        state.eventModal.data = action.payload.data;
        state.eventModal.state = true;
      } else {
        state.eventModal.state = false;
      }
    },
  },
});

export const {
  handleModal,
  handleSongModal,
  handlePlaylistModal,
  handleArtistModal,
  handleEventModal,
  handleSetModal,
} = ModalSlice.actions;

export default ModalSlice.reducer;
